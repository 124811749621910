import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import InputField from "Components/Shared/textField";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { getQueryString, request } from "Utils";
import DialogComponent from "Components/Shared/dailog";
import { TransactionFilterFunc } from "./type";
import { useEffect, useState } from "react";
import { ConfigsInt } from "Components/Configuration/type";

const OpenTransactionFilterAlign: TransactionFilterFunc = ({ setFilter }) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [countries, setCountries] = useState<Array<ConfigsInt>>();

  const listCountries = async () => {
    try {
      const { data } = await request.get({ url: "/configurations" });
      setCountries([...data]);
      openDialog();
    } catch (error) {}
  };

  useEffect(() => {
    listCountries();
  }, [setFilter]);

  function submitForm() {
    setFilter(getQueryString(values));
    closeDialog();
  }

  const status = ["PENDING", "SUCCESSFUL", "FAILED", "UNKNOWN"];
  const purposes = [
    "FUND_WALLET",
    "BANK_TRANSFER",
    "WALLET_TRANSFER",
    "TRADE_SETTLEMENT",
    "REFUND",
  ];
  const types = ["DEBIT", "CREDIT"];
  const paymentGateway = ["SAFE_HAVEN", "MONIRATES", "PAYBOX", "PAWAPAY"];
  const paymentMethod = ["CARD", "WALLET", "BANK_TRANSFER"];

  return (
    <div>
      {/* <MenuOutlinedIcon onClick={listCountries} sx={{fontSize: 40, cursor: "pointer"}} /> */}
      {/* <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Filter"
        maxWidth="sm"
      > */}
      <form onSubmit={submit}>
        <Stack spacing={3} py={2}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <InputField
              type="number"
              label="Page number"
              name="pageId"
              onChange={getData}
              sx={{ width: { xs: "100%", md: "15%" } }}
            />
            <InputField
              type="number"
              label="Limit"
              name="limit"
              onChange={getData}
              sx={{ width: { xs: "100%", md: "15%" } }}
            />
            <FormControl sx={{ width: { xs: "100%", md: "15%" } }}>
              <InputLabel>Offer Status</InputLabel>
              <Select
                name="status"
                labelId="status"
                label="Select status"
                // value={"PERCENTAGE" || "FIXED" || "TEXT"}
                onChange={(e: any) => getData(e)}
              >
                {status?.map((status, index) => (
                  <MenuItem key={`${index}-status`} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "15%" } }}>
              <InputLabel>Purpose</InputLabel>
              <Select
                name="purpose"
                labelId="Purpose"
                label="Select purpose"
                onChange={(e: any) => getData(e)}
              >
                {purposes?.map((purpose, index) => (
                  <MenuItem key={`${index}-purpose`} value={purpose}>
                    {purpose}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "15%" } }}>
              <InputLabel>Payment Method</InputLabel>
              <Select
                name="paymentMethod"
                labelId="payment Method"
                label="Select paymet Method"
                onChange={(e: any) => getData(e)}
              >
                {paymentMethod?.map((paymentMethod, index) => (
                  <MenuItem
                    key={`${index}-paymentMethod`}
                    value={paymentMethod}
                  >
                    {paymentMethod}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: { xs: "100%", md: "15%" } }}>
              <InputLabel>Payment Gateway</InputLabel>
              <Select
                name="paymentGateway"
                labelId="Payment Gateway"
                label="Select paymentGateway"
                onChange={(e: any) => getData(e)}
              >
                {paymentGateway?.map((paymentGate, index) => (
                  <MenuItem
                    key={`${index}-payment gate way`}
                    value={paymentGate}
                  >
                    {paymentGate}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "15%" } }}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                labelId="Type"
                label="Select type"
                onChange={(e: any) => getData(e)}
              >
                {types?.map((type, index) => (
                  <MenuItem key={`${index}-type`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "15%" } }}>
              <InputLabel>Select Currency</InputLabel>
              <Select
                name="currency"
                labelId="Currency"
                label="Select Currency"
                onChange={(e: any) => getData(e)}
              >
                {countries?.map((country, index) => (
                  <MenuItem key={`${index}-type`} value={country.currency}>
                    {country.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <InputField
              type="text"
              label="Start Date"
              name="startDate"
              onChange={getData}
              sx={{ width: { xs: "100%", md: "15%" }, mb: 1 }}
            />
            <InputField
              type="text"
              label="End Date"
              name="endDate"
              onChange={getData}
              sx={{ width: { xs: "100%", md: "15%" }, mb: 1 }}
            />
            <InputField
              type="text"
              label="Reference"
              name="reference"
              onChange={getData}
              sx={{ width: { xs: "100%", md: "15%" }, mb: 1 }}
            />
            <ActionButton
              type="submit"
              variant="contained"
              sx={{ width: { xs: "100%", md: "15%" }, mb: 1 }}
            >
              Filter
            </ActionButton>
          </Box>
        </Stack>
      </form>
      {/* </DialogComponent> */}
    </div>
  );
};

export default OpenTransactionFilterAlign;
