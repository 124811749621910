import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { request, toLocaleDate } from "Utils";
import NoContentFound from "Components/Shared/noContentFound";
import { GetBdcFunc, ListInt } from "./type";
import { Container } from "@mui/material";
import BdcMenu from "./menu";


const getBdcList = async ({ queryKey }: any) => {
    const [, pageId, limit] = queryKey;
    const { data } = await request.get({
        url: `/bdc/list`,
    });
    return data;
};

const GetBdcList: GetBdcFunc = () => {
    const [pageId, setPageId] = useState(1);
    const [limit, setLimit] = useState(10);
    const [paginatedData, setPaginatedData] = useState([]);
    const { data, error, isLoading, refetch } = useQuery({
        queryKey: ["Users", pageId, limit],
        queryFn: getBdcList,
        retry: 2,
        // staleTime: 86400000,
    });

    useEffect(() => {
        if (data) {
            const startIndex = (pageId - 1) * limit;
            const endIndex = startIndex + limit;
            setPaginatedData(data.slice(startIndex, endIndex));
        }
    }, [data, pageId, limit]);

    const columns = [
        { minWidth: 20, name: "No", key: "index" },
        { minWidth: 20, name: "User ID", key: "userId" },
        { minWidth: 50, name: "Name", key: "name" },
        { minWidth: 50, name: "Status", key: "status" },
        { minWidth: 30, name: "Online Status", key: "isOnline" },
        { minWidth: 50, name: "Created At", key: "createdAt" },
        { minWidth: 50, name: "Action", key: "action" },
    ];
    const bdcLists = paginatedData?.map((list: ListInt, index: number) => ({
        index: ++index + limit * (pageId - 1),
        ...list,
        isOnline: list.isOnline ? "YES" : "NO",
        createdAt: toLocaleDate(list?.createdAt),
        action: <BdcMenu bdcInfo={list} />
    }));
    if (isLoading) return <Loading />;
    if (error) return <ErrorPage error={error} />;

    return (
        <Wrapper>
            <Container maxWidth="lg" sx={{height : '85.5vh'}}>
                <div>
                    {bdcLists?.length ? (
                        <StickyHeadTable
                            refetch={refetch}
                            data={bdcLists}
                            dataLength={data?.length}
                            columns={columns}
                            setLimit={setLimit}
                            limit={limit}
                            setPageId={setPageId}
                            pageId={pageId}
                        />
                    ) : (
                        <NoContentFound />
                    )}
                </div>
            </Container>
        </Wrapper>
    );
};


export default GetBdcList;