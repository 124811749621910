import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import StickyHeadTable from "Components/Shared/table";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import TransactionMenu from "./menu";
import { TransactionInt, TransactionsItem } from "./type";
import { useSearchParams } from "react-router-dom";
import NoContentFound from "Components/Shared/noContentFound";
import TransactionFilter from "./filter_align";
import { Card, CardContent } from "@mui/material";

const getWallets = async ({ queryKey }: any) => {
  const [id] = queryKey;
  const query = { id };
  const { data } = await request.get({ url: `/transaction/${id}` });
  return data;
};

const TransactionItem: TransactionsItem = () => {
  const { toggleToast, toastMessage } = useToast();
  const [getQuery] = useSearchParams();
  const id = getQuery.get("id");
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [id],
    queryFn: getWallets,
    retry: 2,
    // staleTime: 86400000,
  });
  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      {!isLoading ? (
        <Box sx={{ height: "85.5vh", position: "relative" }}>
          {/* <Box textAlign="right">
          <TransactionFilter setFilter={setFilter} />
        </Box> */}

          <Card sx={{ mt: 5.5, boxShadow: "0px 1px 70px 1px #f1f1f1" }}>
            <CardContent sx={{ textAlign: "start" }}>
              <div>
                <p>Transaction Date: {data.createdAt}</p>
                <p> Transaction Id: {data.id}</p>
                <p>
                  Amount:{data.currencySymbol} {data.amount}
                </p>
                <p>Transaction Status: {data.status}</p>
                <p>Transaction Type {data.type}</p>
                <p>Payment Method: {data.paymentMethod}</p>

                <p>Purpose:{data.purpose}</p>

                <p>Payment Gateway: {data.paymentGateway}</p>
                <p>
                  {" "}
                  Payment Gateway Reference: {data.paymentGatewayReference}
                </p>
                <p>Fee: {data.fee}</p>
                <p>Fee Transaction Id: {data.feeTransactionId}</p>
                <p>Narration: {data.narration}</p>
                <p>Offer Id: {data.offerGlobalId}</p>
                <p>Order Id:{data.orderGlobalId}</p>

                <p>Reference: {data.reference}</p>

                <p>Refund Id: {data.refundTransactionId}</p>

                {/* <p> {data.updatedAt}</p> */}

                <p>Value Given: {data.valueGiven}</p>
                <hr />
                <h2>Metadata</h2>
                {data.metadata && (
                  <>
                    <div>
                      Sender: {data.metadata.sender?.name} (
                      {data.metadata.sender?.email})
                    </div>
                    <div>
                      Sender Wallet Id: {data.metadata.sender?.walletId}
                    </div>
                    <br />
                    <div>
                      Receiver: {data.metadata.receiver?.name}(
                      {data.metadata.receiver?.email})
                    </div>
                    <div>
                      Receiver Wallet Id: {data.metadata.receiver?.walletId}
                    </div>
                  </>
                )}
              </div>
            </CardContent>
          </Card>

          {/* <StickyHeadTable
          refetch={refetch}
          data={transactions}
          dataLength={data?.totalTransactions}
          columns={columns}
          setLimit={setLimit}
          limit={limit}
          setPageId={setPageId}
          pageId={pageId}
        /> */}
          {Boolean(toastMessage) && (
            <Toast
              open={Boolean(toastMessage)}
              message={toastMessage}
              onClose={() => toggleToast("")}
            />
          )}
        </Box>
      ) : (
        <NoContentFound />
      )}
    </Wrapper>
  );
};

export default TransactionItem;
