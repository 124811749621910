import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useMenu, useToast } from "Utils/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { OfferMenuFunc } from "./type";
import { getQueryString, request } from "Utils";
import { useMutation, useQueryClient } from "react-query";
import ActionButton from "Components/Shared/button";
import { useEffect, useState } from "react";
import { UsersDetails } from "Utils/interface";

const cancelOffer = async (userId: string) => {
  await request.delete(`/offer/${userId}/cancel`);
};

const OfferMenu: OfferMenuFunc = (props) => {
  const [userDetails, setUserDetails] = useState<UsersDetails | null>(null);
  useEffect(() => {
    const userDetailsString = localStorage.getItem("user");
    if (userDetailsString) {
      const userDetailsObject = JSON.parse(userDetailsString);
      setUserDetails(userDetailsObject);
    }
  }, []);
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  const { toggleToast, toastMessage, closeToast, toastOptions } = useToast();
  const [getQuery] = useSearchParams();
  const status = getQuery.get("status");
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(() => cancelOffer(props.offerGlobalId), {
    onSuccess: () => {
      toggleToast("User deleted successfully");
      // You can navigate to a different page or take any other action after deletion
      queryClient.invalidateQueries(["user", props.offerGlobalId]);
      navigate(".", { replace: true });
    },
    onError: (error: any) => {
      toggleToast(`Error deleting user: ${error.message}`);
    },
    onSettled: () => {
      // Invalidate and refetch the user data query to reflect the changes
      queryClient.invalidateQueries(["user", props.offerGlobalId]);
    },
  });

  const handleCancel = () => {
    toggleToast("Are you sure you want to delete this user?", {
      variant: "warning",
      actionButtons: [
        {
          label: "Confirm",
          onClick: () => {
            mutation.mutate();
            closeToast(); // Close the toast after confirming deletion
          },
        },
        {
          label: "Cancel",
          onClick: () => closeToast(), // Close the toast without deleting
        },
      ],
    });
  };

  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <Link to={`/orders?offerId=${props.offerGlobalId}`}>
            <MenuItem disableRipple>View Offer Orders</MenuItem>
          </Link>
          <Link to={`/transactions/offer/${props.globalId}`}>
            <MenuItem disableRipple>View Offer Transaction</MenuItem>
          </Link>
          {status === "OPEN" && userDetails?.role === "SUPER_ADMIN" && (
            <MenuItem
              onClick={handleCancel}
              sx={{ color: "red" }}
              disableRipple
            >
              Cancel Offer
            </MenuItem>
          )}
        </div>
      </Menus>
      <Snackbar
        open={Boolean(toastMessage)}
        autoHideDuration={6000} // Adjust as needed
        onClose={() => closeToast()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Change anchor origin to top right
      >
        <Alert
          onClose={() => {
            closeToast();
            window.location.reload();
          }}
          severity={toastOptions.variant as AlertColor} // Explicitly cast to AlertColor
          sx={{
            width: "400px", // Adjust the width as needed
            fontSize: "18px", // Adjust the font size as needed
            borderRadius: "8px", // Add border-radius for a finer look
            padding: "16px", // Add padding for a bigger look
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Add box-shadow for a subtle elevation
            backgroundColor: "white",
            color: "black",
          }}
        >
          {toastMessage}
          {/* Render action buttons based on toastOptions */}
          {toastOptions.actionButtons && (
            <div>
              {toastOptions.actionButtons.map((button) => (
                <ActionButton
                  onClickCapture={() => {
                    window.location.reload(); // This will force a page refresh
                  }}
                  variant="contained"
                  sx={{
                    marginLeft: "5px",
                    marginTop: "5px",
                    paddingY: "5px",
                    paddingX: "10px",
                  }}
                  key={button.label}
                  onClick={button.onClick}
                >
                  {button.label}
                </ActionButton>
              ))}
            </div>
          )}
        </Alert>
      </Snackbar>
    </>
  );
};
export default OfferMenu;
