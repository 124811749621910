import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";

import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { UpdateConfigtypeFunc } from "./type";

const UpdateConfigtype: UpdateConfigtypeFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  config,
  refetch,
}) => {
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.patch({
        url: `/configuration/${config.id}`,
        data: values,
      });
    },
    {
      onSuccess: () => {
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
        closeDialog();
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title={`Update ${config.country}  config`}
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <InputField
              type="text"
              label="Country"
              name="country"
              defaultValue={config.country}
              onChange={getData}
            />
            <InputField
              type="text"
              label="currency"
              name="currency"
              defaultValue={config.currency}
              onChange={getData}
            />
            <InputField
              type="text"
              label="iso2"
              name="iso2"
              defaultValue={config.iso2}
              onChange={getData}
            />

            <ActionButton type="submit" variant="contained">
              Update config{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};

export default UpdateConfigtype;
