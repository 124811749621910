import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request, toLocaleDate } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import { KycRequestInt, KycRequestPageFunc } from "./type";
import KycMenu from "./menu";

const getKycRequests = async ({ queryKey }: any) => {
  const { data } = await request.get({ url: `/kyc-requests` });
  return data;
};

const KycRequestPagePending: KycRequestPageFunc = () => {
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [],
    queryFn: getKycRequests,
    retry: 2,
    // staleTime: 86400000,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Id", key: "_id" },
    { minWidth: 50, name: "First Name", key: "first_name" },
    { minWidth: 50, name: "Last Name", key: "last_name" },
    { minWidth: 50, name: "Purpose", key: "purpose" },
    { minWidth: 50, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Action", key: "action" },
  ];
  const kycRequests = data
    ?.filter((kycRequest: KycRequestInt) => kycRequest?.status !== "APPROVED")
    .map((kycRequest: KycRequestInt, index: number) => ({
      index: ++index + limit * (pageId - 1),
      ...kycRequest,
      first_name: kycRequest?.userId?.firstname,
      last_name: kycRequest?.userId?.lastname,
      createdAt: toLocaleDate(kycRequest?.createdAt),
      status: kycRequest?.status,
      action: (
        <KycMenu
          userId={kycRequest?.userId?._id}
          kycRequest={kycRequest}
          toggleToast={toggleToast}
          refetch={refetch}
        />
      ),
    }));

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {kycRequests && data?.length ? (
          <Box pb={5}>
            <StickyHeadTable
              refetch={refetch}
              data={kycRequests}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};
export default KycRequestPagePending;
