import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import DialogComponent from "Components/Shared/dailog";
import { CreateBdcFunc } from "./type";
import { useMutation } from "react-query";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import InputField from "Components/Shared/textField";

const CreateBDC: CreateBdcFunc = ({
    userId,
    toggleToast,
    refetch,
    userGlobalId,
}) => {
    const { isOpen, closeDialog, openDialog } = useDailog();
    const { getData, values, submit } = useForm(submitForm);
    const [isLoading, setIsloading] = useState(false);


    const mutation = useMutation(
        async () => {
            const bdcValues = { ...values, name: values.name, userId: userId, userGlobalId: userGlobalId }
            setIsloading(true);
            return await request.post({
                url: `/bdc/create`,
                data: bdcValues,
            });
        },
        {
            onSuccess: ({ message }) => {
                toggleToast(message);
                refetch();
                setIsloading(false);
                closeDialog();
            },
            onError: (err) => {
                toggleToast(handleError(err).message);
                setIsloading(false);
            },
        },
    );
    function submitForm() {
        mutation.mutate();
    }

    return (
        <div>
            <MenuItem onClick={openDialog}>Create BDC</MenuItem>
            <DialogComponent
                open={isOpen}
                onClose={closeDialog}
                title="Create BDC"
                maxWidth="sm"
            >
                <form onSubmit={submit}>
                    <Stack spacing={3} py={3}>
                        <InputField
                            type="email"
                            label="Email"
                            name="name"
                            onChange={getData}
                            sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}
                        />
                        <ActionButton type="submit" variant="contained">
                            Create BDC
                            {isLoading && (
                                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                            )}
                        </ActionButton>
                    </Stack>
                </form>
            </DialogComponent>
        </div>
    );
};

export default CreateBDC;
