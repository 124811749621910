import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import { useDailog, useForm, useToast } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import InputField from "Components/Shared/textField";
import DialogComponent from "Components/Shared/dailog";
import { ViewUserKycPageFunc } from "./type";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import { Box } from "@mui/system";
import Image from "Components/Shared/image";
import { request } from "Utils";
import { useMutation } from "react-query";

const ViewUserKyc: ViewUserKycPageFunc = ({ kycRequest, toggleToast, refetch }) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const [isApproved, setIsApproved] = useState("");
  const [reason, setReason] = useState("");
  const [otherReasons, setOtherReasons] = useState("");
  const { getData, values, submit } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false);
  const [approve, SetApprove] = useState(false);

  const Reasons = [
    "Complete Information",
    "Incomplete Information",
    "Expired Documents",
    "Poor Quality Document/image.",
    "Mismatched Information/image",
    "Fake or Forged Documents",
    "Unacceptable Document Types",
    "Non-Compliance with Regulatory Requirements",
    "Document Not Recognized",
    "Mismatch with Database Records",
    "others",
  ];
  const status = [
    {
      value: "true",
      title: "Approved",
    },
    { value: "false", title: "Rejected" },
  ];

  const handleApprove = (value: any) => {
    if (value === 'false') {
      SetApprove(true)
    } else if (value === 'true') {
      SetApprove(false)
    }
  }


  const mutation = useMutation(
    async () => {
      setIsloading(true);
      if (isApproved == "true") {
        var approval = true;
      } else {
        var approval = false;
      }
      if (reason == "others") {
        var Reason = otherReasons;
      } else {
        var Reason = reason;
      }
      let body = {}
      if (approve) {
        body = { isApproved: approval, reason: Reason }
      } else {
        body = { isApproved: approval }
      }

      return await request.post({
        url: `/kyc-request/${kycRequest["_id"]}`,
        data: body,
      });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: ({ message }) => {
        toggleToast(message);
        setIsloading(false);
        closeDialog();
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }

  return (
    <div>
      <MenuItem onClick={openDialog}>View Kyc Request</MenuItem>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="User Kyc Details"
        maxWidth="md"
      >
        <Stack spacing={3} py={2}>
          <div className="text-center">
            <h2>
              Full Names: {kycRequest?.userId?.firstname}{" "}
              {kycRequest?.userId?.lastname}
            </h2>
            <div>
              <h2>Purpose: {kycRequest?.purpose}</h2>
            </div>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              {kycRequest?.fields.map((item, index) => {
                return (
                  <div key={`${index}-${item}`}>
                    <a href={item} target="_blank">
                      {" "}
                      <Image src={item} alt={item} height={150} width={150} />
                    </a>
                  </div>
                );
              })}
            </Box>
          </div>

          <form
            onSubmit={submit}
          >
            <Stack spacing={3} py={3}>
              <Box>
                <FormControl
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Approval Status</InputLabel>
                  <Select
                    name="status"
                    labelId="status"
                    label="Select status"
                    defaultValue={Reasons[0]}
                    onChange={(e: any) => {
                      setIsApproved(e.target.value);
                      handleApprove(e.target.value)
                    }}
                  >
                    {status?.map((status, index) => (
                      <MenuItem key={`${index}-status`} value={status.value}>
                        {status.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <br />
                </FormControl>
                {
                  approve && <FormControl
                    sx={{
                      width: { xs: "100%", md: "100%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Reasons</InputLabel>
                    <Select
                      name="Reason"
                      labelId="Reason"
                      label="Select Reason"
                      onChange={(e: any) => {
                        setReason(e.target.value);
                      }}
                    >
                      {Reasons?.map((reasons, index) => (
                        <MenuItem key={`${index}-status`} value={reasons}>
                          {reasons}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
                {reason == "others" && (
                  <InputField
                    fullWidth
                    type="text"
                    label="Please Type Other Reasons Here"
                    name="Reasons"
                    onChange={(e: any) => {
                      setOtherReasons(e.target.value);
                    }}
                  />
                )}
              </Box>

              <ActionButton type="submit" variant="contained">
                Confirm
                {isLoading && (
                  <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
                )}
              </ActionButton>
            </Stack>
          </form>
        </Stack>
      </DialogComponent>
    </div>
  );
};

export default ViewUserKyc;
