import Box from "@mui/material/Box";

import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { request } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import { OrderInt, OrderOffersPageFunc } from "./type";
import { useNavigate, useParams } from "react-router-dom";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";

const getOrderOffers = async ({ queryKey }: any) => {
  const [, pageId, limit, offerId] = queryKey;
  const { data } = await request.get({
    url: `/order/offer/${offerId}`,
  });
  return data;
};

const OrderOffersPage: OrderOffersPageFunc = () => {
  const { offerId } = useParams();
  const navigate = useNavigate();
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["Users", pageId, limit, offerId],
    queryFn: getOrderOffers,
  });
  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 50, name: "Seller", key: "seller" },
    { minWidth: 50, name: "Buyer", key: "buyer" },
    { minWidth: 50, name: "Amount", key: "amount" },
    { minWidth: 50, name: "Display Rate", key: "displayRate" },
    { minWidth: 50, name: "From Currency", key: "fromCurrency" },
    { minWidth: 50, name: "To Currency", key: "toCurrency" },
    { minWidth: 50, name: "Status", key: "status" },
  ];

  const orders = data?.orders?.map((order: OrderInt, index: number) => ({
    index: ++index + limit * (pageId - 1),

    ...order,
    amount: `${order.currencySymbol} ${order.amount.toLocaleString("en")}`,
    displayRate: `${order.currencySymbol} ${order.displayRate}`,
    seller: `${order?.sellerGlobalId?.firstname} ${order?.sellerGlobalId?.lastname}`,
    buyer: `${order?.buyerGlobalId?.firstname} ${order?.buyerGlobalId?.lastname}`,
    fromCurrency: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={order?.fromCurrency?.flag}
          alt={order.fromCurrency.symbol}
          variant="square"
          sx={{ width: 20, height: 15, mr: 0.5 }}
        />
        <Typography fontWeight={400}>{order?.fromCurrency?.name}</Typography>
      </Box>
    ),
    toCurrency: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={order?.toCurrency?.flag}
          alt={order.toCurrency.symbol}
          variant="square"
          sx={{ width: 20, height: 15, mr: 0.5 }}
        />
        <Typography fontWeight={400}>{order?.toCurrency?.name}</Typography>
      </Box>
    ),
  }));

  const viewTransaction = (value: Record<string, any>) => {
    navigate(`/transactions?orderGlobalId=${value.globalId}`);
  };

  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <ErrorPage error={error} />
        ) : data?.orders?.length ? (
          <Box pb={5}>
            <StickyHeadTable
              refetch={refetch}
              data={orders}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
              onClick={viewTransaction}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}

        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default OrderOffersPage;
