import DialogComponent from "Components/Shared/dailog";
import { Box, Typography, Grid } from "@mui/material";
import { ExchangeFeePaymentfunc, PaymentDetailsFunc } from "./type";

const PaymentDetails: PaymentDetailsFunc = ({
    isOpen,
    closeDialog,
    bdcInfo
}) => {
    const paymentDetails = bdcInfo?.paymentDetails || {};

    return (
        <div>
            <DialogComponent
                open={isOpen}
                onClose={closeDialog}
                title="Payment Details"
                maxWidth="md"
            >
                <Box p={2}>
                    {Object.keys(paymentDetails).length === 0 ? (
                        <Typography variant="h5">No Payment Details
                        available.</Typography>
                    ) : (
                        <Grid container spacing={2}>
                            {Object.entries(paymentDetails).map(([key, value]) => (
                                <Grid item xs={6} md={6} key={key}>
                                    <Box border={1} borderRadius={2} p={2}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '20px', fontWeight: 'bolder' }}>{key}</Typography>
                                        <Typography variant="body1">Bank Code: {value?.bankCode}</Typography>
                                        <Typography variant="body1">Account Number: {value?.accountNumber}</Typography>
                                        <Typography variant="body1">Account Name: {value?.accountName}</Typography>
                                        <Typography variant="body1">Currency: {(value?.currency)}</Typography>
                                        <Typography variant="body1">Iso2: {(value?.iso2)}</Typography>
                                        <Typography variant="body1">Payment Method: {(value?.paymentMethod)}</Typography>
                                        <Typography variant="body1">Provider Name: {(value?.providerName)}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
            </DialogComponent>
        </div>
    );
};

export default PaymentDetails;
