import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import { useEffect, useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { AddCountryTypeFunc } from "./type";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { getAllISOCodes } from "iso-country-currency";


const AddCountry: AddCountryTypeFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
}) => {
  const { getData, values, submit, reset } = useForm(submitForm);
  const [isLoading, setIsloading] = useState(false)
  const countryInformation = getAllISOCodes()


  const mutation = useMutation(
    async () => {
      const { exchangeCurrency, rate, ...restValues } = values;
      const exchangeRateObject = { [exchangeCurrency]: rate };
      const updatedValues = { ...restValues, exchangeRate: exchangeRateObject };
      setIsloading(true);
      return await request.post({
        url: "/school-config",
        data: updatedValues,
      });
    },
    {
      onSuccess: ({ message }) => {
        reset();
        toggleToast(message);
        refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: (err) => {
        toggleToast(handleError(err).message);
        setIsloading(false);
      },
    },
  );
  function submitForm() {
    mutation.mutate();
  }



  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Add Country"
        maxWidth="xs"
      >
        <form onSubmit={submit}>
          <Stack spacing={4} py={4}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <FormControl sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}>
                <InputLabel>Country Name</InputLabel>
                <Select
                  name="country"
                  labelId="country"
                  label="Country Name"
                  onChange={(e: any) => getData(e)}
                >
                  {countryInformation?.map((country, index) => (
                    <MenuItem key={`${index}-country`} value={country.countryName}>
                      {country.countryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "100%" }, mb: 3 }}>
                <InputLabel>Currency</InputLabel>
                <Select
                  name="currency"
                  labelId="currency"
                  label="Currency"
                  onChange={(e: any) => getData(e)}
                >
                  {countryInformation
                    ?.filter((value, index, self) =>
                      self.findIndex((item) => item.currency === value.currency) === index // Filter out duplicates
                    )
                    .sort((a, b) => a.currency.localeCompare(b.currency)) // Sort by currency alphabetically
                    .map((country, index) => (
                      <MenuItem key={`${index}-country`} value={country.currency}>
                        {country.currency}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "48%" }, mb: 3 }}>
                <InputLabel>ISO2</InputLabel>
                <Select
                  name="iso2"
                  labelId="iso2"
                  label="ISO2"
                  onChange={(e: any) => getData(e)}
                >
                  {countryInformation?.map((country, index) => (
                    <MenuItem key={`${index}-country`} value={country.iso}>
                      {country.iso}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputField
                type="number"
                label="Charges"
                name="charges"
                onChange={getData}
                required
                sx={{ width: { xs: "100%", md: "48%" }, mb: 3 }}
              />
              <InputField
                type="number"
                label="Limit"
                name="limit"
                onChange={getData}
                required
                sx={{ width: { xs: "100%", md: "48%" }, mb: 3 }}
              />
               <FormControl sx={{ width: { xs: "100%", md: "48%" }, mb: 3 }}>
                <InputLabel>Exchange Currency</InputLabel>
                <Select
                  name="exchangeCurrency"
                  labelId="exchangeCurrency"
                  label="Exchange Currency"
                  onChange={(e: any) => getData(e)}
                >
                 {countryInformation
                    ?.filter((value, index, self) =>
                      self.findIndex((item) => item.currency === value.currency) === index // Filter out duplicates
                    )
                    .sort((a, b) => a.currency.localeCompare(b.currency)) // Sort by currency alphabetically
                    .map((country, index) => (
                      <MenuItem key={`${index}-country`} value={country.currency}>
                        {country.currency}
                      </MenuItem>
                    ))}
                    </Select>
              </FormControl>
              <InputField
                type="number"
                label="Exchange Rate"
                name="rate"
                onChange={getData}
                required
                sx={{ width: { xs: "100%", md: "48%" }, mb: 3 }}
              />
            </Box>
            <ActionButton type="submit" variant="contained">
              Add Country{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default AddCountry;
