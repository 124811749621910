import Stack from "@mui/material/Stack";
import InputField from "Components/Shared/textField";
import { useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { handleError, request } from "Utils";
import { useState } from "react";
import Progress from "Components/Shared/circleLoading";
import DialogComponent from "Components/Shared/dailog";
import { useMutation } from "react-query";
import { ApprovePaymentTypeFunc } from "./type";
import { Box, Typography } from "@mui/material";


const ApprovePayment: ApprovePaymentTypeFunc = ({
  toggleToast,
  isOpen,
  closeDialog,
  refetch,
  selectedTransactions
}) => {

  const [isLoading, setIsloading] = useState(false);

  const mutation = useMutation(
    async () => {
      setIsloading(true);
      return await request.get({
        url: `/school-fee-payment/${selectedTransactions._id}/approve`,
    });
    },
    {
      onSuccess: ({ message }) => {
        toggleToast(message);
        refetch && refetch();
        setIsloading(false);
        closeDialog();
      },
      onError: (err) => {
        toggleToast(handleError(err).message);
        setIsloading(false);
      },
    },
  );

  return (
    <div>
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Approve Payment"
        maxWidth="xs"
      >
        <Box py={2}>
          <Typography variant="body1" sx={{ fontSize: 16, lineHeight: 1.6 }}>
            Please confirm your intention to approve this payment
          </Typography>
          <Box textAlign="right">
            <ActionButton
              onClick={() => mutation.mutate()}
              sx={{ color: "red", fontSize: 16 }}
            >
              Yes, Approve{" "}
              {isLoading && (
                <Progress sx={{ color: "#fff", ml: 0.8 }} size={18} />
              )}
            </ActionButton>
            <ActionButton
              onClick={() => closeDialog()}
              sx={{ color: "#616161", fontSize: 16 }}
            >
              No, Cancel
            </ActionButton>
          </Box>
        </Box>
      </DialogComponent>
    </div>
  );
};
export default ApprovePayment;
