import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { request } from "Utils";
import { List, PushNotificationFunc } from "./type";
import Wrapper from "Components/Wrapper";
import { Box, Button, CircularProgress, Stack, TextField } from "@mui/material";
import InputField from "Components/Shared/textField";
import ChipInput from "./chipInput";
import { Send } from "@mui/icons-material";

const TITLE_MAX_LENGTH = 40;
const MESSAGE_MAX_LENGTH = 240;

const PushNotification: PushNotificationFunc = () => {
    const [getQuery] = useSearchParams();
    const userId = getQuery.get("userId");
    const firstname = getQuery.get("firstname");
    const [sentStatus, setSentStatus] = useState({
        type: "empty",
        message: "",
    });
    const [notificationBody, setNotificationBody] = useState({
        title: "",
        message: "",
    });
    const [userList, setUserList] = useState<List[]>([]);
    const location = useLocation();
    const { data } = location.state || {};

    useEffect(() => {
        const tempList = new Set(userList.map((user) => user.userId));
        const newList = [...userList];
        if (data != undefined) {
            const itemData = JSON.parse(data);
            itemData.forEach((item: any) => {
                const user = {
                    userId: item.id,
                    firstname: item.firstname,
                };

                if (!tempList.has(user.userId)) {
                    tempList.add(user.userId);
                    newList.push(user);
                }
            });
        } else if (userId != null && firstname != null) {
            const user = {
                userId: userId,
                firstname: firstname,
            };

            if (!tempList.has(user.userId)) {
                tempList.add(user.userId);
                newList.push(user);
            }
        }

        setUserList(newList);
    }, [data, firstname, userId]);

    const sendPushNotification = async () => {
        const uniqueIdList = Array.from(
            new Map(userList.map((user) => [user.userId, user])).values()
        );
        const pushBody = {
            message: notificationBody.message,
            title: notificationBody.title,
            recipientIds: uniqueIdList.map((user) => user.userId),
        };
        setSentStatus({
            type: "loading",
            message: "Sending Push Notification",
        });

        console.log(pushBody);
        try {
            const { data } = await request.post({
                url: "/utility/send-bulk-notification",
                data: pushBody,
            });
            setSentStatus({
                type: "Success",
                message: "Push Notification Sent Successfully",
            });
        } catch (error: any) {
            setSentStatus({
                type: "Error",
                message: error.message,
            });
        }

        setTimeout(() => {
            setSentStatus({
                type: "empty",
                message: "",
            });
        }, 3000);
    };

    const handlePaste = (
        e: React.ClipboardEvent,
        maxLength: number,
        setState: React.Dispatch<
            React.SetStateAction<{
                title: string;
                message: string;
            }>
        >,
        field: "title" | "message"
    ) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        setState((prevState) => {
            let newValue = prevState[field] + text;
            if (newValue.length > maxLength) {
                newValue = newValue.slice(0, maxLength);
            }
            return { ...prevState, [field]: newValue };
        });
    };

    return (
        <Wrapper>
            <Box sx={{ height: "85.5vh", position: "relative" }}>
                <Stack spacing={1} py={1}>
                    {sentStatus.type !== "empty" && (
                        <p
                            style={
                                sentStatus.type == "Success"
                                    ? {
                                        color: "green",
                                        fontSize: 20,
                                        marginBottom: 10,
                                        fontWeight: "bold",
                                    }
                                    : {
                                        color: "red",
                                        fontSize: 20,
                                        marginBottom: 10,
                                        fontWeight: "bold",
                                    }
                            }
                        >
                            {sentStatus.type === "loading" ? (
                                <CircularProgress />
                            ) : (
                                sentStatus.message
                            )}
                        </p>
                    )}

                    <div>
                        <InputField
                            type="text"
                            label="Title"
                            name="title"
                            onChange={(e: any) => {
                                if (e.target.value.length <= TITLE_MAX_LENGTH) {
                                    setNotificationBody({
                                        ...notificationBody,
                                        title: e.target.value,
                                    });
                                }
                            }}
                            onPaste={(e: React.ClipboardEvent) =>
                                handlePaste(e, TITLE_MAX_LENGTH, setNotificationBody, "title")
                            }
                            required
                            value={notificationBody.title}
                            helperText={`${notificationBody.title.length}/${TITLE_MAX_LENGTH}`}
                            sx={{ width: { xs: "100%", md: "75%" }, mb: 5 }}
                        />
                        <ChipInput label="Recipients" id="recipients" users_list={userList} />
                        <TextField
                            sx={{ width: { xs: "100%", md: "75%" }, mb: 5 }}
                            label="Message"
                            name="message"
                            value={notificationBody.message}
                            onChange={(e: any) => {
                                if (e.target.value.length <= MESSAGE_MAX_LENGTH) {
                                    setNotificationBody({
                                        ...notificationBody,
                                        message: e.target.value,
                                    });
                                }
                            }}
                            onPaste={(e: React.ClipboardEvent) =>
                                handlePaste(e, MESSAGE_MAX_LENGTH, setNotificationBody, "message")
                            }
                            multiline
                            rows={4}
                            helperText={`${notificationBody.message.length}/${MESSAGE_MAX_LENGTH}`}
                        />
                        <Stack spacing={10} direction="row">
                            <Button
                                onClick={sendPushNotification}
                                variant="contained"
                                endIcon={<Send />}
                                sx={{
                                    fontSize: "20px",
                                    padding: "10px 40px",
                                    marginBottom: "1500px",
                                }}
                            >
                                Send Push Notification
                            </Button>
                        </Stack>
                    </div>
                </Stack>
            </Box>
        </Wrapper>
    );
};

export default PushNotification;
