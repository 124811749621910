import React from 'react'
import { CountryTypeMenuFunc } from './type'
import { useDailog, useMenu } from 'Utils/hooks';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import Menus from 'Components/Shared/menu';
import { MenuItem } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import PaidIcon from '@mui/icons-material/Paid';
import { useNavigate } from 'react-router-dom';
import UpdateCountryInfo from './updateCountry';
import UpdateExchangeRate from './updateExchangeRate'
import FlagIcon from '@mui/icons-material/Flag';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDelete from "Components/Shared/confirmDelete";



const CountryMenu: CountryTypeMenuFunc = (props) => {
  const navigate = useNavigate()
  const updateDialog = useDailog()
  const updateExchangeDialog = useDailog()
  const confirmDeleteModal = useDailog()
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          <MenuItem onClick={() => navigate(`/pay-tuition/${props.country.iso2}/schools`)}>
            <SchoolIcon /> View Schools
          </MenuItem>
          <MenuItem onClick={updateDialog.openDialog}>
            <FlagIcon /> Update Country Information
          </MenuItem>
          <MenuItem onClick={updateExchangeDialog.openDialog} >
            <CurrencyExchangeIcon /> View / Update Countries Exchange Rate
          </MenuItem>
          <MenuItem
            onClick={confirmDeleteModal.openDialog}
            sx={{ color: "red" }}
            disableRipple
          >
            <DeleteIcon />
            Delete Country
          </MenuItem>
        </div>
      </Menus>

      <UpdateCountryInfo
        refetch={props.refetch}
        isOpen={updateDialog.isOpen}
        closeDialog={updateDialog.closeDialog}
        toggleToast={props.toggleToast}
        countryInfo={props.country} />

      <UpdateExchangeRate
        refetch={props.refetch}
        isOpen={updateExchangeDialog.isOpen}
        closeDialog={updateExchangeDialog.closeDialog}
        toggleToast={props.toggleToast}
        countryInfo={props.country} />

      <ConfirmDelete
        refetch={props.refetch}
        isOpen={confirmDeleteModal.isOpen}
        closeDialog={confirmDeleteModal.closeDialog}
        toggleToast={props.toggleToast}
        url={`/school-config/${props.country.id}`}
      />
    </>
  )
}

export default CountryMenu