import { Routes, Route } from "react-router-dom";
import LoginPage from "Services/User/login";
import PrivateRoutes from "./privateRoutes";
import DashboardPage from "Components/Dashboard";
import UsersPage from "Components/Users";
import KYCtypes from "Components/kyc";
import Transactions from "Components/transactions";
import FilterTransactions from "Components/FilterTransactions";
import OrdersPage from "Components/Orders";
import OrderOffersPage from "Components/Orders/offerOrders";
import OffersPage from "Components/Offers";
import UserKYC from "Components/Users/kycUsers";
import Subscribers from "Components/Subscribers";
import Configuration from "Components/Configuration";
import AdminPage from "Components/admin";
import RolesPage from "Components/Roles";
import ConfigurationType from "Components/ConfigurationType";
import { session } from "Utils";
import WalletsPage from "Components/Users/wallet";
import OrderStatisticsPage from "Components/Orders/orderStatistics";
import OffersStatsPage from "Components/Offers/offerStats";
import OfferTransactions from "Components/transactions/offerTransaction";
import TransactionItem from "Components/transactions/transaction_item";
import OffersStatistics from "Components/Offers/offer_statistics";
import UserData from "Components/Users/userData";
import Balances from "Components/Balance";
import KycRequestPage from "Components/KycRequests";
import KycRequestPageApproved from "Components/KycRequests/approvedRequest";
import KycRequestPagePending from "Components/KycRequests/pendingRequest";
import EmailPage from "Components/SendMail";
import VASPage from "Components/VAS";
import PayTuition from "Components/PayTuition";
import ViewSchools from "Components/PayTuition/viewSchools";
import TuitionTransactions from "Components/Tuition Approval";
import PushNotification from "Components/PushNotification";
import GetBdcList from "Components/BdcList";

export default function RoutesPage() {
  const token = session.get("token");
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={token ? <DashboardPage /> : <LoginPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/transaction-details" element={<TransactionItem />} />
        <Route path="/transactions/stats" element={<FilterTransactions />} />
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/offers" element={<OffersPage />} />
        <Route path="/offers-statistics" element={<OffersStatistics />} />
        <Route path="/order/offer/:offerId" element={<OrderOffersPage />} />
        <Route path="/vas/:iso2" element={<VASPage />} />
        <Route
          path="/transactions/offer/:offerId"
          element={<OfferTransactions />}
        />
        <Route path="/kyc-types" element={<KYCtypes />} />
        <Route path="/kyc/user/:userId" element={<UserKYC />} />
        <Route path="/subscribers" element={<Subscribers />} />
        <Route path="/config-type" element={<ConfigurationType />} />
        <Route path="/configurations" element={<Configuration />} />
        <Route path="/kyc-requests" element={<KycRequestPage />} />
        <Route
          path="/kyc-requests/approved"
          element={<KycRequestPageApproved />}
        />
        <Route
          path="/kyc-requests/pending"
          element={<KycRequestPagePending />}
        />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/wallet" element={<WalletsPage />} />
        <Route path="/roles" element={<RolesPage />} />
        <Route path="/orders/stats" element={<OrderStatisticsPage />} />
        <Route path="/offers/stats" element={<OffersStatsPage />} />
        <Route path="/user" element={<UserData />} />
        <Route path="/balance-summary" element={<Balances />} />
        <Route path="/send-mail" element={<EmailPage />} />
        <Route path="/pay-tuition" element={<PayTuition /> } />
        <Route path="/pay-tuition/:iso2/schools" element={<ViewSchools />} />
        <Route path="/school-fee-payment" element={<TuitionTransactions />} />
        <Route path="/push-notification" element={<PushNotification />} />
        <Route path="/bdc-list" element={<GetBdcList />} />
      </Route>
    </Routes>
  );
}
