import AddOutlined from "@mui/icons-material/AddOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ListIcon from "@mui/icons-material/List";
import PeopleOutlineOutlined from "@mui/icons-material/PeopleOutlineOutlined";
import LocalOfferOutlined from "@mui/icons-material/LocalOfferOutlined";
import ShoppingCartOutlined from "@mui/icons-material/ShoppingCartOutlined";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import EditOutlined from "@mui/icons-material/EditOutlined";
import DeleteForever from "@mui/icons-material/DeleteForeverOutlined";
import DialogComponent from "Components/Shared/dailog";
import Box from "@mui/material/Box";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { useDailog } from "Utils/hooks";
import { useNavigate } from "react-router-dom";
import AssignKYC from "Components/kyc/assignKYC";
import UpdateConfig from "./update";
import ConfirmDelete from "Components/Shared/confirmDelete";
import Configurations from "./configurations";
import ListCurrencyKYC from "Components/kyc/listCurrencyKYC";
import { ConfigmenuFunc } from "./type";
import AddConfigConfig from "./addConfig";
import Grid from "@mui/material/Grid";
import MenuComponent from "./menuComponent";
import { useState } from "react";
import { request } from "Utils";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import KYCrequest from "Components/kyc/kycRequest";

const Configmenu: ConfigmenuFunc = (props) => {
  const confirmDeleteModal = useDailog();
  const configurationModal = useDailog();
  const navigate = useNavigate();

  const assignKYCModal = useDailog();
  const [data, setData] = useState([]);

  async function getKYCS() {
    try {
      const { data } = await request.get({
        url: "/kyc-types",
      });
      setData(data);
      assignKYCModal.openDialog();
    } catch (error) {
      console.log(error);
    }
  }

  const kycTypeModal = useDailog();
  const viewKYCModal = useDailog();
  const addConfigModal = useDailog();
  const updateConfig = useDailog();
  const [viewKYC, setViewKYC] = useState<Array<any>>([]);
  const [kycTypes, setKYCtypes] = useState<Array<any>>([]);
  const [isLoadingKYCTypes, setIsloadingKYCTypes] = useState(false);
  const [addConfigTypes, setAddConfigTypes] = useState<Array<any>>([]);

  async function getKYCList() {
    try {
      setIsloadingKYCTypes(true);
      const { data } = await request.get({
        url: `/wallet-kyc/currency/${props.currency}`,
      });
      setKYCtypes([...data]);
      setIsloadingKYCTypes(false);
      kycTypeModal.openDialog();
    } catch (err: any) {
      props.toggleToast(err.message);
    }
  }

  async function viewKYCList() {
    try {
      setIsloadingKYCTypes(true);
      const { data } = await request.get({
        url: "/kyc-types",
      });
      setViewKYC([...data]);
      setIsloadingKYCTypes(false);
      viewKYCModal.openDialog();
    } catch (err: any) {
      props.toggleToast(err.message);
    }
  }

  async function getConfigTypes() {
    try {
      const { data } = await request.get({ url: "/config-types" });
      data.length !== props.configurations.length &&
        setAddConfigTypes([...data]);
      addConfigModal.openDialog();
    } catch (error) {
      console.log(error);
    }
  }

  const menuItems = [
    { icon: <AddOutlined />, name: "Assign KYC", onclick: getKYCS },
    { icon: <FormatListBulletedIcon />, name: "List KYC", onclick: getKYCList },
    {
      icon: <RequestPageOutlinedIcon />,
      name: "View KYC",
      onclick: viewKYCList,
    },
    {
      icon: <DesignServicesIcon />,
      name: "Vas",
      onclick: () => navigate(`/vas/${props.iso2}`),
    },
    {
      icon: <ShoppingCartOutlined />,
      name: "Orders",
      onclick: () => navigate(`/orders?fromCurrency=${props.currency}`),
    },
    {
      icon: <LocalOfferOutlined />,
      name: "Offers",
      onclick: () => navigate(`/offers?fromCurrency=${props.currency}`),
    },
    {
      icon: <PeopleOutlineOutlined />,
      name: "Users",
      onclick: () => navigate(`/users?iso2=${props.iso2}`),
    },
    {
      icon: <PointOfSaleIcon />,
      name: "Transactions",
      onclick: () => navigate(`/transactions?currency=${props.currency}`),
    },
    {
      icon: <AddCircleOutlineOutlined />,
      name: "Update Config",
      onclick: getConfigTypes,
    },
    {
      icon: <ListIcon />,
      name: "Config",
      onclick: configurationModal.openDialog,
    },
    {
      icon: <EditOutlined />,
      name: "Update",
      onclick: updateConfig.openDialog,
    },
    {
      icon: <DeleteForever style={{ color: "red" }} />,
      name: "Delete",
      onclick: confirmDeleteModal.openDialog,
    },
  ];
  return (
    <>
      <DialogComponent
        open={props.menuIsOpen}
        onClose={props.closeMenu}
        title="Configuration Menu"
      >
        <Box pb={1} sx={{}}>
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, lg: 3 }}
          >
            {menuItems.map((menuItem, index) => (
              <Grid item xs={4} sm={3} key={index}>
                <MenuComponent
                  {...menuItem}
                  deleteComponent={
                    index === menuItems.length - 1 ? true : false
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* </Menus> */}
        <Configurations
          isOpen={configurationModal.isOpen}
          closeDialog={configurationModal.closeDialog}
          toggleToast={props.toggleToast}
          configurations={props.configurations}
          refetch={props.refetch}
          id={props.id}
        />
        <UpdateConfig
          refetch={props.refetch}
          isOpen={updateConfig.isOpen}
          closeDialog={updateConfig.closeDialog}
          toggleToast={props.toggleToast}
          config={{
            country: props.country,
            id: props.id,
            iso2: props.iso2,
            currency: props.currency,
          }}
        />
        <ConfirmDelete
          isOpen={confirmDeleteModal.isOpen}
          closeDialog={confirmDeleteModal.closeDialog}
          toggleToast={props.toggleToast}
          url={`/configuration/${props.id}`}
          refetch={props.refetch}
        />
        <AssignKYC
          toggleToast={props.toggleToast}
          currency={props.currency}
          data={data}
          dialogModal={assignKYCModal}
        />
        <ListCurrencyKYC
          toggleToast={props.toggleToast}
          currency={props.currency}
          dialogModal={kycTypeModal}
          isLoading={isLoadingKYCTypes}
          setKYCtypes={setKYCtypes}
          kycTypes={kycTypes}
        />
        <AddConfigConfig
          refetch={props.refetch}
          dialogModal={addConfigModal}
          toggleToast={props.toggleToast}
          id={props.id}
          configurations={props.configurations}
          addConfigTypes={addConfigTypes}
        />
        <KYCrequest
          toggleToast={props.toggleToast}
          currency={props.currency}
          isLoading={isLoadingKYCTypes}
          setKYCtypes={setViewKYC}
          kycTypes={viewKYC}
          dialogModal={viewKYCModal}
        />
      </DialogComponent>
    </>
  );
};
export default Configmenu;
