import Menus from "Components/Shared/menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { useDailog, useMenu } from "Utils/hooks";
import { Link } from "react-router-dom";
import { OrderMenuFunc } from "./type";
import OrderDetails from "./orderDetails";

const OrderMenu: OrderMenuFunc = (props) => {
  const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
  const { isOpen, openDialog, closeDialog } = useDailog()
  return (
    <>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
        <div>
          {/* <Link to={`/orders/offer/${props.offerGlobalId}`}>
            <MenuItem disableRipple>View Offer Orders</MenuItem>
          </Link> */}
          {/* <Link to={`/transactions/offer/${props.globalId}`}>
            <MenuItem disableRipple>View Order Transactions</MenuItem>
          </Link> */}
          {/* <Link to={`/transactions?orderGlobalId=${props.globalId}`}>
            <MenuItem disableRipple>View Order Transactions</MenuItem>
          </Link> */}
          <MenuItem onClick={openDialog}>
            View Bank Details
          </MenuItem>
        </div>
      </Menus>
      <OrderDetails
        isOpen={isOpen}
        closeDialog={closeDialog}
        order={props.order}
      />
    </>
  );
};
export default OrderMenu;
