import Box from "@mui/material/Box";
import ErrorPage from "Components/Shared/error";
import Loading from "Components/Shared/loading";
import Wrapper from "Components/Wrapper";
import { useState } from "react";
import { useQuery } from "react-query";
import { getQueryString, request, toLocaleDate } from "Utils";
import { useToast } from "Utils/hooks";
import Toast from "Components/Shared/toast";
import NoContentFound from "Components/Shared/noContentFound";
import StickyHeadTable from "Components/Shared/table";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { OrderInt, OrdersPageFunc } from "./type";
import OrderFilterAlign from "./order_filter_h";
import OrderMenu from "./menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography, Tooltip, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const getOrders = async ({ queryKey }: any) => {
  const [
    ,
    { pageId, limit, filter }
  ] = queryKey;
  const query = {
    pageId,
    limit,
  };
  const queryParams = filter ? `${filter}&pageId=${pageId}&limit=${limit || 10}` : getQueryString(query);
  const { data } = await request.get({ url: `/orders${queryParams}` });
  return data;
};

const OrdersPage: OrdersPageFunc = () => {
  const navigate = useNavigate();
  const { toggleToast, toastMessage } = useToast();
  const [pageId, setPageId] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState("");
  const [getQuery] = useSearchParams();
  const query = useLocation();
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: [
      "Orders",
      {
        pageId,
        limit,
        filter: query.search
      }
    ],
    queryFn: getOrders,
    retry: 2,
  });

  const columns = [
    { minWidth: 20, name: "No", key: "index" },
    { minWidth: 70, name: "Seller", key: "seller" },
    { minWidth: 70, name: "Buyer", key: "buyer" },
    { minWidth: 80, name: "Amount", key: "amount" },
    { minWidth: 150, name: "Rate", key: "rate" },
    { minWidth: 50, name: "From Currency", key: "fromCurrency" },
    { minWidth: 50, name: "To Currency", key: "toCurrency" },
    { minWidth: 50, name: "Buyer Paid", key: "buyerMadePayment" },
    { minWidth: 50, name: "Seller Paid", key: "sellerMadePayment" },
    { minWidth: 100, name: "Created At", key: "createdAt" },
    { minWidth: 50, name: "Status", key: "status" },
    { minWidth: 50, name: "Order Reference", key: "globalId" },
    { minWidth: 50, name: "Action", key: "action" },
  ];

  const orders = data?.orders?.map((order: OrderInt, index: number) => ({
    index: ++index + limit * (pageId - 1),
    ...order,
    amount: `${order?.fromCurrency} ${order.amount.toLocaleString("en")}`,
    rate: `1${order?.fromCurrency} = ${order?.toCurrency} ${order?.rate?.toFixed(3)}`,
    seller: `${order?.sellerGlobalId?.firstname} ${order?.sellerGlobalId?.lastname}`,
    buyer: `${order?.buyerGlobalId?.firstname} ${order?.buyerGlobalId?.lastname}`,
    fromCurrency: order?.fromCurrency,
    toCurrency: order?.toCurrency,
    action: <OrderMenu offerGlobalId={order._id} globalId={order.globalId} order={order} />,
    status: (
      <>
        {order.status === 'PENDING' ? <Typography sx={{ color: 'red' }}>{order.status}</Typography> : order.status === 'PROCESSING' ? <Typography sx={{ color: '#b28900' }}>{order.status}</Typography> : <Typography sx={{ color: 'darkgreen' }}>{order.status}</Typography>}
      </>
    ),
    buyerMadePayment: `${order?.buyerMadePayment === true ? 'YES' : 'NO'}`,
    sellerMadePayment: `${order?.sellerMadePayment === true ? 'YES' : 'NO'}`,
    createdAt: toLocaleDate(order?.createdAt),
    globalId: (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title={order.globalId}>
          <span>{`${order.globalId.substring(0, 4)}...`}</span>
        </Tooltip>
        <IconButton
          size="small"
          onClick={() => navigator.clipboard.writeText(order.globalId)}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Box>
    ),
  }));

  const viewTransaction = (value: Record<string, any>) => {
    navigate(`/transactions?orderGlobalId=${value.globalId}`);
  };

  if (isLoading) return <Loading />;
  if (error) return <ErrorPage error={error} />;
  return (
    <Wrapper>
      <Box sx={{ height: "85.5vh", position: "relative" }}>
        {data?.orders?.length ? (
          <Box pb={5}>
            {filter ? (
              <Box
                onClick={() => window.location.reload()}
                marginTop="-40px"
                display="flex"
                alignItems="center"
              >
                <ArrowBackIcon
                  style={{ fontSize: 40 }}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                />
              </Box>
            ) : (
              ""
            )}
            <OrderFilterAlign setFilter={setFilter} />
            <StickyHeadTable
              refetch={refetch}
              data={orders}
              dataLength={data?.totalCount}
              columns={columns}
              setLimit={setLimit}
              limit={limit}
              setPageId={setPageId}
              pageId={pageId}
              pageCount={data.pageCount}
            />
          </Box>
        ) : (
          <NoContentFound />
        )}
        {Boolean(toastMessage) && (
          <Toast
            open={Boolean(toastMessage)}
            message={toastMessage}
            onClose={() => toggleToast("")}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default OrdersPage;
