import React, { useState } from 'react'
import { PaymentMenuFunc, SchoolInfoInt } from './type'
import { useDailog, useMenu } from 'Utils/hooks';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import Menus from 'Components/Shared/menu';
import { MenuItem } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ApprovePayment from './approvePayment';
import { request } from 'Utils';
import SchoolInformation from './schoolInfo';



const PaymentMenu: PaymentMenuFunc = (props) => {
    const { isOpen, openDialog, closeDialog } = useDailog()
    const SchoolInfoDialog = useDailog()
    const { anchorEl, menuIsOpen, closeMenu, openMenu } = useMenu();
    const [schoolInfo, setSchoolInfo] = useState({
        name: '',
        accountNo: '',
        bankName: '',
        bankAddress: '',
        accountName: '',
        iban: '',
        swiftCode: '',
        _id: ''
    })


    async function getSchoolInfo() {
        try {
            const { data } = await request.get({
                url: `/school-config/${props.countryId}`,
            });
            if (data && data.schools) {
                let filteredSchool = data.schools.find((school: any) => school._id === props.schoolId);
                setSchoolInfo(filteredSchool);
                SchoolInfoDialog.openDialog()
            }
        } catch (error) {
            console.error("Error fetching school info:", error);
        }
    }


    return (
        <>
            <IconButton onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <Menus anchorEl={anchorEl} open={menuIsOpen} closeMenu={closeMenu}>
                <div>
                    {props.transaction.status === 'PENDING' ? <MenuItem onClick={openDialog}>
                        <TaskAltIcon /> Approve Payment
                    </MenuItem> : ''}
                    <MenuItem onClick={getSchoolInfo}>
                        <SchoolIcon /> View School Information
                    </MenuItem>
                </div>
            </Menus>
            <ApprovePayment
                refetch={props.refetch}
                isOpen={isOpen}
                closeDialog={closeDialog}
                toggleToast={props.toggleToast}
                selectedTransactions={props.transaction}
            />
            <SchoolInformation
                refetch={props.refetch}
                isOpen={SchoolInfoDialog.isOpen}
                closeDialog={SchoolInfoDialog.closeDialog}
                toggleToast={props.toggleToast}
                school={schoolInfo}
            />

        </>
    )
}

export default PaymentMenu