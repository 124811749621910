import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import InputField from "Components/Shared/textField";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { getQueryString, request } from "Utils";
import DialogComponent from "Components/Shared/dailog";
import { UserFilterFunc } from "./type";
import { useEffect, useState } from "react";
import { ConfigsInt } from "Components/Configuration/type";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const UserFilter: UserFilterFunc = ({ sendBulkMail, sendPushNotification }) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [countries, setCountries] = useState<Array<ConfigsInt>>();
  const [supportedCurrency, setSupportedCurrency] = useState<string[]>();
  const navigate = useNavigate();
  const query = useLocation();

  let params;
  useEffect(() => {
    params = query.search?.split("?")[1];
    params = params?.split("&");
    params?.map((item) => {
      const key = item.split("=");
      values[key[0]] = key[1];
    });
  }, [params]);

  const getFilterNeededData = async () => {
    try {
      const [{ data: currencies }, { data }] = await Promise.all([
        request.get({ url: "/utility/countries" }),
        request.get({
          url: "/wallet/supported-currencies?showAll=false",
        }),
      ]);
      setCountries([...currencies]);
      setSupportedCurrency(data);
      openDialog();
    } catch (error) { }
  };

  function submitForm() {
    const queryString = getQueryString(values);
    navigate(`/users${queryString}`, { replace: true });
  }


  const status = [
    "SUSPENDED",
    "BLOCKED",
    "DEACTIVATED",
    "ACTIVE",
    "UNVERIFIED",
  ];
  const hasDoneGeneralKyc = ["true", "false"];
  const referredUsers = ["true", "false"]

  return (
    <div>
      <MenuOutlinedIcon
        onClick={getFilterNeededData}
        sx={{ fontSize: 40, mb: 2, cursor: "pointer" }}
      />
      <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Filter"
        maxWidth="sm"
      >
        <form onSubmit={submit}>
          <Stack spacing={3} py={3}>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                // justifyContent: "space-between",
                gap: "5px"
              }}
            >
              <InputField
                type="text"
                label="Email"
                name="email"
                onChange={getData}
                value={values.email || ''}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
              />
              <InputField
                type="text"
                label="First Name"
                name="firstname"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
                value={values.firstname || ''}
              />
              <InputField
                type="text"
                label="Last Name"
                name="lastname"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
                value={values.lastname || ''}
              />
              {/* <InputField
                type="number"
                label="Page number"
                name="pageId"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
                value={values.pageId}
              />
              <InputField
                type="number"
                label="Limit"
                name="limit"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
                value={values.limit}
              /> */}
              <InputField
                type="string"
                label="Phone Number"
                name="phoneNumber"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
                value={values.phoneNumber || ''}
              />
              <FormControl sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}>
                <InputLabel>User Status</InputLabel>
                <Select
                  name="status"
                  labelId="status"
                  label="Select status"
                  onChange={(e: any) => getData(e)}
                  value={values.status || ''}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {status?.map((status, index) => (
                    <MenuItem key={`${index}-status`} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}>
                <InputLabel>Select Nationality</InputLabel>
                <Select
                  name="nationality"
                  labelId="Nationalities"
                  label="Select Nationality"
                  onChange={(e: any) => getData(e)}
                  value={values.nationality || ''}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countries?.map((country, index) => (
                    <MenuItem key={`${index}-type`} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}>
                <InputLabel>Select Currency</InputLabel>
                <Select
                  name="currency"
                  labelId="Currency"
                  label="Select Currency"
                  onChange={(e: any) => getData(e)}
                  value={values.currency || ''}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {supportedCurrency?.map((item, index: any) => (
                    <MenuItem key={`${index}-type`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}>
                <InputLabel>General KYC</InputLabel>
                <Select
                  name="hasDoneGeneralKyc"
                  labelId="hasDoneGeneralKyc"
                  label="Select status"
                  onChange={(e: any) => getData(e)}
                  value={values.hasDoneGeneralKyc || ''}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {hasDoneGeneralKyc?.map((hasDoneGeneralKyc, index: any) => (
                    <MenuItem
                      key={`${index}-hasDoneGeneralKyc`}
                      value={hasDoneGeneralKyc}
                    >
                      {hasDoneGeneralKyc == "true" ? "YES" : "NO"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}>
                <InputLabel>Referred Users</InputLabel>
                <Select
                  name="referredUsers"
                  labelId="referredUsers"
                  label="Select status"
                  onChange={(e: any) => getData(e)}
                  value={values.referredUsers || ''}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {referredUsers?.map((referredUsers, index: any) => (
                    <MenuItem
                      key={`${index}-referredUsers`}
                      value={referredUsers}
                    >
                      {referredUsers == "true" ? "YES" : "NO"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputField
                type="text"
                label="Referral Username"
                name="referralUsername"
                onChange={getData}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
                value={values.referralUsername || ''}
              />
              <TextField
                type="date"
                label="Created At"
                name="createdAt"
                onChange={getData}
                value={values.createdAt}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: { xs: "100%", md: "32.3%" }, mb: 3 }}
              />
              <ActionButton
                sx={{
                  width: { xs: "100%", md: "30%" },
                  mb: 3,
                  backgroundColor: "#192259"
                }}
                type="submit"
                variant="contained"
              >
                Filter
              </ActionButton>
              {/* <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 3, // Adjust gap as needed
                  mb: 3
                }}
              > */}
              <ActionButton
                onClick={() => {
                  sendBulkMail();
                }}
                type="button"
                sx={{
                  width: { xs: "100%", md: "30%" },
                  mb: 2,
                  backgroundColor: "#32.32259",
                  "&:hover": {
                    backgroundColor: "#32.32259",
                  },
                }}
                variant="contained"
              >
                Send Bulk Mail
              </ActionButton>
              <ActionButton
                onClick={() => {
                  sendPushNotification();
                }}
                type="button"
                sx={{
                  width: { xs: "100%", md: "40%" },
                  mb: 2,
                  backgroundColor: "#606676",
                  "&:hover": {
                    backgroundColor: "#606676",
                  },
                }}
                variant="contained"
              >
                Send Push Notification
              </ActionButton>
              {/* </Box> */}

            </Box>
          </Stack>
        </form>
      </DialogComponent>
    </div>
  );
};
export default UserFilter;
