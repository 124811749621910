import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import InputField from "Components/Shared/textField";
import { useDailog, useForm } from "Utils/hooks";
import ActionButton from "Components/Shared/button";
import { getQueryString, request } from "Utils";

import { TransQueryValues, TransactionFilterFunc } from "./type";
import { useEffect, useState } from "react";
import { ConfigsInt } from "Components/Configuration/type";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const TransactionFilterAlign: TransactionFilterFunc = ({
  setFilter,
  filter,
}) => {
  const { isOpen, closeDialog, openDialog } = useDailog();
  const { getData, values, submit } = useForm(submitForm);
  const [countries, setCountries] = useState<Array<ConfigsInt>>();

  const listCountries = async () => {
    try {
      const { data } = await request.get({ url: "/configurations" });
      setCountries([...data]);
      openDialog();
    } catch (error) {}
  };

  useEffect(() => {
    listCountries();
  }, [setFilter]);

  function submitForm() {
    setFilter(getQueryString(values));
    closeDialog();
  }

  const extractValuesFromQueryString = (
    queryString: string,
  ): TransQueryValues => {
    const params = new URLSearchParams(queryString);
    return {
      offerStatus: params.get("status"),
      currency: params.get("currency"),
      purpose: params.get("purpose"),
      paymentMethodValue: params.get("paymentMethod"),
      paymentGatewayValue: params.get("paymentGateway"),
      type: params.get("type"),
      pageId: params.get("pageId") ? parseInt(params.get("pageId")!, 10) : null,
      limit: params.get("limit") ? parseInt(params.get("limit")!, 10) : null,
      startDate: params.get("startDate"),
      endDate: params.get("endDate"),
      reference: params.get("reference"),
    };
  };

  const {
    pageId,
    limit,
    offerStatus,
    purpose,
    paymentMethodValue,
    paymentGatewayValue,
    type,
    currency,
    startDate,
    endDate,
    reference,
  } = extractValuesFromQueryString(filter);

  const status = ["PENDING", "SUCCESSFUL", "FAILED", "UNKNOWN"];
  const purposes = [
    "FUND_WALLET",
    "BANK_TRANSFER",
    "WALLET_TRANSFER",
    "TRADE_SETTLEMENT",
    "REFUND",
  ];
  const types = ["DEBIT", "CREDIT"];
  const paymentGateway = ["SAFE_HAVEN", "MONIRATES", "PAYBOX", "PAWAPAY"];
  const paymentMethod = ["CARD", "WALLET", "BANK_TRANSFER"];

  return (
    <div>
      {/* <MenuOutlinedIcon onClick={listCountries} sx={{fontSize: 40, cursor: "pointer"}} /> */}
      {/* <DialogComponent
        open={isOpen}
        onClose={closeDialog}
        title="Filter"
        maxWidth="sm"
      > */}
      <form onSubmit={submit}>
        <Stack spacing={3} py={2}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: 3,
            }}
          >
            {/* <InputField
              type="number"
              label="Page number"
              name="pageId"
              onChange={getData}
              defaultValue={pageId}
              sx={{ width: { xs: "100%", md: "15%" } }}
            />
            <InputField
              type="number"
              label="Limit"
              name="limit"
              onChange={getData}
              defaultValue={limit}
              sx={{ width: { xs: "100%", md: "15%" } }}
            /> */}
            <FormControl sx={{ width: { xs: "100%", md: "18%" } }}>
              <InputLabel>Transaction Status</InputLabel>
              <Select
                name="status"
                labelId="status"
                label="Select status"
                defaultValue={offerStatus}
                // value={"PERCENTAGE" || "FIXED" || "TEXT"}
                onChange={(e: any) => getData(e)}
              >
                {status?.map((status, index) => (
                  <MenuItem key={`${index}-status`} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "18%" } }}>
              <InputLabel>Purpose</InputLabel>
              <Select
                name="purpose"
                labelId="Purpose"
                label="Select purpose"
                defaultValue={purpose}
                onChange={(e: any) => getData(e)}
              >
                {purposes?.map((purpose, index) => (
                  <MenuItem key={`${index}-purpose`} value={purpose}>
                    {purpose}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "18%" } }}>
              <InputLabel>Payment Method</InputLabel>
              <Select
                name="paymentMethod"
                labelId="payment Method"
                label="Select paymet Method"
                defaultValue={paymentMethodValue}
                onChange={(e: any) => getData(e)}
              >
                {paymentMethod?.map((paymentMethod, index) => (
                  <MenuItem
                    key={`${index}-paymentMethod`}
                    value={paymentMethod}
                  >
                    {paymentMethod}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: { xs: "100%", md: "18%" } }}>
              <InputLabel>Payment Gateway</InputLabel>
              <Select
                name="paymentGateway"
                labelId="Payment Gateway"
                label="Select paymentGateway"
                defaultValue={paymentGatewayValue}
                onChange={(e: any) => getData(e)}
              >
                {paymentGateway?.map((paymentGate, index) => (
                  <MenuItem
                    key={`${index}-payment gate way`}
                    value={paymentGate}
                  >
                    {paymentGate}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "18%" } }}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                labelId="Type"
                label="Select type"
                onChange={(e: any) => getData(e)}
                defaultValue={type}
              >
                {types?.map((type, index) => (
                  <MenuItem key={`${index}-type`} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", md: "18%" } }}>
              <InputLabel>Select Currency</InputLabel>
              <Select
                name="currency"
                labelId="Currency"
                label="Select Currency"
                defaultValue={currency}
                onChange={(e: any) => getData(e)}
              >
                {countries?.map((country, index) => (
                  <MenuItem key={`${index}-type`} value={country.currency}>
                    {country.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              type="date"
              label="Start Date"
              name="startDate"
              onChange={getData}
              defaultValue={startDate}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ width: { xs: "100%", md: "18%" }, mb: 1 }}
            />
            <TextField
              type="date"
              label="End Date"
              name="endDate"
              onChange={getData}
              defaultValue={endDate}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"></InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true, // This will prevent the placeholder from floating to the top
              }}
              sx={{ width: { xs: "100%", md: "18%" }, mb: 1 }}
            />
            <InputField
              type="text"
              label="Reference"
              name="reference"
              onChange={getData}
              defaultValue={reference}
              sx={{ width: { xs: "100%", md: "18%" }, mb: 1 }}
            />
            <ActionButton
              type="submit"
              variant="contained"
              sx={{ width: { xs: "100%", md: "18%" }, mb: 1 }}
            >
              Filter
            </ActionButton>
          </Box>
        </Stack>
      </form>
      {/* </DialogComponent> */}
    </div>
  );
};

export default TransactionFilterAlign;
